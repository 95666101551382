<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>营销管理</template>
      <template v-slot:secondMenu>优惠券管理</template>
    </breadcrumb-nav>
    <el-card>
      <el-button type="primary" @click="submitCoupon">确认提交</el-button>
      <el-button type="primary" @click="returnBack">返回</el-button>
      <el-form style="margin-top: 20px" size="mini" :model="form" :rules="formRules" ref="form" label-width="100px"
               label-position="right">
        <el-row :gutter="5" style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="标题" prop="title">
                <el-input style="width: 100%" v-model="form.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="类型" prop="type">
              <el-select v-model="form.type" style="width:100%" placeholder="请选择优惠券类型">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
            <el-form-item label="发放场景" prop="sence">
              <el-select v-model="form.sence" style="width:100%" placeholder="请选择发放场景">
                <el-option
                  v-for="item in senceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="使用范围" prop="scope">
              <el-select v-model="form.scope" style="width:100%" placeholder="请选择使用范围">
                <el-option
                  v-for="item in scopeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="获取方式" prop="obtainType">
              <el-select v-model="form.obtainType" style="width:100%" placeholder="请选择获取方式">
                <el-option
                  v-for="item in obtainTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="扣减金额" prop="reduceAmount">
              <el-input type="Number" style="width: 100%" v-model="form.reduceAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用门槛" prop="minPoint">
              <el-input type="Number" style="width: 100%" v-model="form.minPoint"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发放限制" prop="quota">
              <el-input type="Number" style="width: 100%" v-model="form.quota"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="时效类型" prop="validType">
              <el-select v-model="form.validType" style="width:100%" placeholder="请选择时效类型">
                <el-option
                  v-for="item in validTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16" v-show="form.validType === 2">
            <el-form-item label="时效(天)" prop="quota">
              <el-input type="Number" style="width: 100%" v-model="form.validTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16" v-show="form.validType === 1">
            <el-form-item label="时效范围" prop="quota">
              <el-date-picker
                style="width: 100%"
                v-model="form.date"
                type="daterange"
                align="right"
                prop="date"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getCouponRequest, addCouponRequest, updateCouponRequest } from '../../../network/coupon'

export default {
  name: 'AddOrUpdateCoupon',
  components: {
    BreadcrumbNav
  },
  filters: {
    typeLabel (value) {
      switch (value) {
        case 1:
          return '满减券'
        default:
          return '其他'
      }
    },
    senceLabel (value) {
      switch (value) {
        case 1:
          return '新人发放'
        case 2:
          return '大转盘中奖'
        case 3:
          return '拆红包中奖'
        default:
          return '其他'
      }
    },
    scopeLabel (value) {
      switch (value) {
        case 1:
          return '全场通用'
        default:
          return '其他'
      }
    },
    obtainTypeLabel (value) {
      switch (value) {
        case 1:
          return '系统发放'
        case 2:
          return '用户领取'
        default:
          return '其他'
      }
    },
    validTypeLabel (value) {
      switch (value) {
        case 1:
          return '绝对时效'
        case 2:
          return '相对时效'
        default:
          return '其他'
      }
    },
    statusLabel (value) {
      switch (value) {
        case 1:
          return '生效'
        case 2:
          return '失效'
        default:
          return '其他'
      }
    }
  },
  data () {
    return {
      form: {
        couponId: 0,
        title: '',
        icon: '',
        type: 1,
        sence: 1,
        scope: 1,
        obtainType: 1,
        reduceAmount: 0,
        minPoint: 0,
        quota: 0,
        validType: 1,
        startTime: 0,
        endTime: 0,
        validTime: 0,
        date: ''
      },
      formRules: {
        title: [
          {
            required: true,
            message: '请输入优惠券标题',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择优惠券类型'
          }
        ],
        sence: [
          {
            required: true,
            message: '请选择优惠券发放场景'
          }
        ],
        scope: [
          {
            required: true,
            message: '请选择优惠券使用范围'
          }
        ],
        obtainType: [
          {
            required: true,
            message: '请选择优惠券获取方式'
          }
        ],
        reduceAmount: [
          {
            required: true,
            message: '请输入优惠券扣减金额',
            trigger: 'blur'
          }
        ],
        minPoint: [
          {
            required: true,
            message: '请输入优惠券使用门槛',
            trigger: 'blur'
          }
        ],
        quota: [
          {
            required: true,
            message: '请输入优惠券发放数量',
            trigger: 'blur'
          }
        ],
        validType: [
          {
            required: true,
            message: '请选择优惠券时效类型'
          }
        ]
      },
      typeOptions: [
        {
          label: '满减券',
          value: 1
        }
      ],
      senceOptions: [
        {
          label: '新人发放',
          value: 1
        },
        {
          label: '大转盘中奖',
          value: 2
        },
        {
          label: '拆红包中奖',
          value: 3
        }
      ],
      scopeOptions: [
        {
          label: '全场通用',
          value: 1
        }
      ],
      obtainTypeOptions: [
        {
          label: '系统发放',
          value: 1
        },
        {
          label: '用户领取',
          value: 2
        }
      ],
      validTypeOptions: [
        {
          label: '绝对时效',
          value: 1
        },
        {
          label: '相对时效',
          value: 2
        }
      ]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    if (this.$route.query.couponId) {
      this.form.couponId = parseInt(this.$route.query.couponId)
      this.getCoupon()
    }
  },
  methods: {
    getCoupon () {
      console.log('ddd:', this.form.couponId)
      getCouponRequest({ couponId: this.form.couponId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取详情失败', 'error')
        }
        console.log('coupon data:', result.data)
        this.form = {
          couponId: result.data.couponId,
          title: result.data.title,
          icon: result.data.icon,
          type: result.data.type,
          sence: result.data.sence,
          scope: result.data.scope,
          obtainType: result.data.obtainType,
          reduceAmount: result.data.reduceAmount,
          minPoint: result.data.minPoint,
          quota: result.data.quota,
          validType: result.data.validType,
          startTime: result.data.startTime,
          endTime: result.data.endTime,
          validTime: result.data.validTime,
          date: [result.data.startTime, result.data.endTime]
        }
      })
    },
    validCouponTime () {
      if (this.form.validType !== 1 && this.form.validType !== 2) {
        return false
      }
      if (this.form.validType === 2 && this.form.validTime === 0) {
        return false
      }
      if (this.form.validType === 1 && (this.form.startTime === 0 || this.form.endTime === 0)) {
        return false
      }
      return true
    },
    validReduceAmount () {
      if (this.form.reduceAmount <= 0) {
        return false
      }
      return true
    },
    validMinPoint () {
      if (this.form.minPoint <= 0) {
        return false
      }
      return true
    },
    submitCoupon () {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return this.alertMessage('请输入完整信息', 'warning')
        }
        if (this.form.date && this.form.date.length > 1) {
          this.form.startTime = this.form.date[0]
          this.form.endTime = this.form.date[1]
        }
        if (!this.validReduceAmount()) {
          return this.alertMessage('请输入优惠券扣减金额', 'warning')
        }
        if (!this.validMinPoint()) {
          return this.alertMessage('请输入优惠券使用门槛', 'warning')
        }
        if (!this.validCouponTime()) {
          return this.alertMessage('请输入优惠券有效期', 'warning')
        }
        this.form.reduceAmount = parseFloat(this.form.reduceAmount)
        this.form.minPoint = parseFloat(this.form.minPoint)
        this.form.validTime = parseInt(this.form.validTime)
        if (this.form.couponId === 0) {
          this.addCoupon()
        } else {
          this.updateCoupon()
        }
        console.log('submit coupon info:', this.form)
      })
    },
    addCoupon () {
      addCouponRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加失败', 'error')
        }
        this.alertMessage('添加成功', 'success')
        this.$router.back()
      })
    },
    updateCoupon () {
      updateCouponRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('更新失败', 'error')
        }
        this.alertMessage('更新成功', 'success')
        this.$router.back()
      })
    },
    returnBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
